import { Atom } from ":mods";
export const ENDPOINTS = {
  get_schedule_list: "/cms/schedule/list/",
};
export const EVENT_CARD_ITEM = {
  event_id: "1",
  title: "Tittle Of The Event",
  event_details: {
    date: "01.06.2023",
    time: "10:00",
    action: "Join live stream",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
    type: "Type of event",
  },
};

export const INSTRUCTIONS = {
  steps: [
    {
      title: "Step 1: ",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    },
    {
      title: "Step 2: ",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    },
    {
      title: "Step 3: ",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    },
  ],
};

export const ROUTES = {
  foundation: (id?: string | number) => `/foundation/schedule/${id ?? ":id"}`,
};
export const ANCHORES = {
  side: {
    admin: {
      foundation: {
        title: "Edit Schedule",
        to: ROUTES.foundation(1),
        icon: {
          idle: "icon-local-nav-side:schedules-idle",
          active: "icon-local-nav-side:schedules-active",
        },
      } as Atom.Route.model.AnchorItem,
    },
    student: {
      foundation: {
        title: "Schedule",
        to: ROUTES.foundation(1),
        icon: {
          idle: "icon-local-nav-side:schedules-idle",
          active: "icon-local-nav-side:schedules-active",
        },
      } as Atom.Route.model.AnchorItem,
    },
    tutor: {
      foundation: {
        title: "Schedule",
        to: ROUTES.foundation(1),
        icon: {
          idle: "icon-local-nav-side:schedules-idle",
          active: "icon-local-nav-side:schedules-active",
        },
      } as Atom.Route.model.AnchorItem,
    },
  },
};
